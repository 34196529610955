import React, { Component}  from 'react';
import styled from 'styled-components';
import desktopBg from "../images/desktop/ohviz-page9.webp";
import mobileBg from "../images/mobile/ohviz-mobilepage8.webp";
import {Grid, Image} from 'semantic-ui-react';
import { isMobile } from "react-device-detect";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        if (isMobile && this.props.orientation === 'Portrait') {
            return (
                <Wrapper ref={this.props.inputRef} id="contact">
                    <StyledBackgroundImage>
                        <Image fluid src={ mobileBg }/>
                        <MobileEmailArea onClick={() => window.location = `mailto:seanohare.design@gmail.com?subject= ohviz enquiry`}/>
                    </StyledBackgroundImage>
                </Wrapper>
            )
        } else if (isMobile && this.props.orientation === 'Landscape') {
            return (
                <Wrapper ref={this.props.inputRef} id="contact">
                    <StyledBackgroundImage>
                        <Image fluid src={ mobileBg }/>
                        <MobileLEmailArea onClick={() => window.location = `mailto:seanohare.design@gmail.com?subject= ohviz enquiry`}/>
                    </StyledBackgroundImage>
                </Wrapper>
            )
        } else {
            return (
                <Wrapper ref={this.props.inputRef} id="contact">
                    <StyledBackgroundImage>
                        <Image fluid src={ desktopBg }/>
                        <EmailArea onClick={() => window.location = `mailto:seanohare.design@gmail.com?subject= ohviz enquiry`}/>
                    </StyledBackgroundImage>
                </Wrapper>
            )
        }
    }
}

const Wrapper = styled.div`
  top: 0vh;
  left: 0vw;
`;

const StyledBackgroundImage = styled.div`
  position: relative;
  z-index: 1;
`;

const MobileEmailArea = styled.div`
  position: absolute;
  height: 30vh;
  width: 60vw;

  top: 50%;
  left: 20%;

  z-index: 2;
`;

const MobileLEmailArea = styled.div`
  position: absolute;
  height: 35%;
  width: 60vw;

  top: 50%;
  left: 20%;

  z-index: 2;
`;

const EmailArea = styled.div`
  position: absolute;
  height: 50vh;
  width: 40vw;

  top: 25%;
  left: 30%;
  right: 30%;

  z-index: 2;
`;

export default Contact;