import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'animate.css';
import 'semantic-ui-css/semantic.min.css';
import {BrowserRouter, Routes, Route } from "react-router-dom";
import Work from "./pages/Work";

const root = ReactDOM.createRoot(
    document.getElementById("root")
);
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="work" element={<Work />} />
        </Routes>
    </BrowserRouter>
);
