import React, { Component} from 'react';
import styled from "styled-components";
import {IconContext} from "react-icons";
import {IoMenuOutline} from "react-icons/io5";

class MobileWorkMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuClicked: false,
            optionsShowing: false
        }
        this.changeOptionsVisibility = this.changeOptionsVisibility.bind(this);
        this.getLocalServer = this.getLocalServer.bind(this);
    }

    changeOptionsVisibility() {
        this.setState({
            menuClicked: !this.state.menuClicked,
            optionsShowing: !this.state.optionsShowing
        });
    }

    getLocalServer() {
        if (window.location.hostname === "localhost" ||
            window.location.hostname === "127.0.0.1" ||
            window.location.hostname === "") {
            return "http://localhost:3000";
        } else if (window.location.hostname === "192.168.1.115") {
            return "192.168.1.115:3000/work";
        } else {
            return "https://ohviz.co.uk";
        }
    }

    render() {
        if (this.props.orientation === 'Portrait') {
            return (
                <MenuBarsDiv onClick={this.changeOptionsVisibility}>
                    { this.state.menuClicked ? (
                        <StyledMenuBackground>
                            <MenuSection onClick={this.changeOptionsVisibility}>
                                <a href={this.getLocalServer()}>
                                    <div>
                                        <StyledMenuText>home</StyledMenuText>
                                    </div>
                                </a>
                            </MenuSection>
                            <MenuSection onClick={this.changeOptionsVisibility}>
                                <a href={this.getLocalServer() + "/#contact"}>
                                    <div>
                                        <StyledMenuText>contact</StyledMenuText>
                                    </div>
                                </a>
                            </MenuSection>
                        </StyledMenuBackground>
                    ) : (
                        <HiddenMenuBackground/>
                    )}
                    <MenuBarsIconDiv>
                        <IconContext.Provider value={{ color: 'white', size: '4.5vh', style: { verticalAlign: 'middle' } }}>
                            <IoMenuOutline/>
                        </IconContext.Provider>
                    </MenuBarsIconDiv>
                </MenuBarsDiv>
            )
        } else {
            return (
                <MenuLBarsDiv onClick={this.changeOptionsVisibility}>
                    { this.state.menuClicked ? (
                        <StyledLMenuBackground>
                            <MenuSection onClick={this.changeOptionsVisibility}>
                                <a href={this.getLocalServer()}>
                                    <div>
                                        <StyledLMenuText>home</StyledLMenuText>
                                    </div>
                                </a>
                            </MenuSection>
                            <MenuSection onClick={this.changeOptionsVisibility}>
                                <a href={this.getLocalServer() + "/#contact"}>
                                    <div>
                                        <StyledLMenuText>contact</StyledLMenuText>
                                    </div>
                                </a>
                            </MenuSection>
                        </StyledLMenuBackground>
                    ) : (
                        <HiddenMenuBackground/>
                    )}
                    <MenuBarsIconDiv>
                        <IconContext.Provider value={{ color: 'white', size: '7.5vh', style: { verticalAlign: 'middle' } }}>
                            <IoMenuOutline/>
                        </IconContext.Provider>
                    </MenuBarsIconDiv>
                </MenuLBarsDiv>
            )
        }

    }
}

const MenuBarsDiv = styled.div`
  position: absolute;
  top: 0.5vh;
  right: 0.5vh;
  height: 5vh;
  width: 10vw;
  text-align: center;
  cursor: pointer;
  z-index: 11;
`;

const MenuLBarsDiv = styled.div`
  position: absolute;
  top: 0.5vh;
  right: 1.5vh;
  height: 5vh;
  width: 5vw;
  text-align: center;
  cursor: pointer;
  z-index: 11;
`;

const HiddenMenuBackground = styled.div`
  position: absolute;
  
  top: 1vh;
  height: 18vh;

  left: -10vw;
  width: 10vw;
  z-index: 10;
  display: none;
`;

const MenuBarsIconDiv = styled.div`
  position: absolute;
  width: 100%;
  z-index: 11;
`;

const MenuSection = styled.div`
    position: relative;
    height: 50%;
    text-align: center;
  
  &:hover {
    background-color: gray;
  }
`;

const StyledMenuBackground = styled.div`
  position: absolute;
  
  top: 1vh;
  height: 10vh;

  right: 11vw;
  width: 10vw;
  z-index: 10;
  display: inline;
`;

const StyledLMenuBackground = styled.div`
  position: absolute;
  
  top: 1vh;
  height: 10vh;

  right: 5vw;
  width: 10vw;
  z-index: 10;
  display: inline;
`;

const StyledMenuText = styled.text`
  font-family: FuturaBook;
  color: white;
  text-align: left;
  font-size: 1.5vh;
`;

const StyledLMenuText = styled.text`
  font-family: FuturaBook;
  color: white;
  text-align: left;
  font-size: 3vh;
`;

export default MobileWorkMenu;