import React, { Component} from 'react';
import styled from "styled-components";
import {Image} from "semantic-ui-react";
import bg from "../images/desktop/ohviz-page8.webp";
import {isDesktop, isMobile} from "react-device-detect";

class About2 extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        if (!isMobile || this.props.orientation === 'Landscape') {
            return (
                <Wrapper ref={this.props.inputRef}>
                    <StyledBackgroundImage>
                        <Image fluid src={ bg }/>
                    </StyledBackgroundImage>
                </Wrapper>
            )
        }
    }
}

const Wrapper = styled.div`
  top: 0vh;
  left: 0vw;
`;

const StyledBackgroundImage = styled.div`
  position: relative;
  z-index: 1;
`;

export default About2;