import React, { Component} from 'react';
import styled from "styled-components";
import { isMobile } from "react-device-detect";

class StyledPageNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        console.log("Page: " + this.props.pageNo)
        if (isMobile && this.props.orientation === 'Portrait') {
            return (
                <StyledMobilePageDiv>
                    <StyledMobilePageNo>
                        <StyledMobileText><b>{this.props.pageNo}</b></StyledMobileText>
                    </StyledMobilePageNo>
                    <StyledMobilePageLocationText>
                        <StyledMobileSecondaryText>{this.props.pageTitle}</StyledMobileSecondaryText>
                    </StyledMobilePageLocationText>
                </StyledMobilePageDiv>
            )
        } else if (isMobile && this.props.orientation === 'Landscape') {
            return (
                <StyledMobileLandsPageDiv>
                    <StyledMobilePageNo>
                        <StyledMobileLandsText><b>{this.props.pageNo}</b></StyledMobileLandsText>
                    </StyledMobilePageNo>
                    <StyledMobileLandsPageLocationText>
                        <StyledMobileLandscapeSecondaryText>{this.props.pageTitle}</StyledMobileLandscapeSecondaryText>
                    </StyledMobileLandsPageLocationText>
                </StyledMobileLandsPageDiv>
            )
        } else {
            return (
                <StyledPageDiv>
                    <StyledPageNo>
                        <StyledText><b>{this.props.pageNo}</b></StyledText>
                    </StyledPageNo>
                    <StyledPageLocationText>
                        <StyledSecondaryText>{this.props.pageTitle}</StyledSecondaryText>
                    </StyledPageLocationText>
                </StyledPageDiv>
            )
       }
    }
}


const StyledPageDiv = styled.div`
  position: absolute;
  
  bottom: -11vh;
  right: -2.5vw;
  
  height: 10vh;
  width: 5vw;
  z-index: 12;
  //background-color: orange;
`;

const StyledPageNo = styled.div`
  position: relative;
  
  top: 1.5vh;
  height: 5vh;
  
  width: 5vw;
  
  text-align: center;
  z-index: 12;
`;

const StyledPageLocationText = styled.div`
  position: relative;
  top: 1vh;
  height: 5vh;
  width: 5vw;

  text-align: center;
  z-index: 10;
`;

const StyledText = styled.text`
  font-family: FuturaHeavy;
  text-align: center;
  font-size: 6vh;
  color: white;
  justify-content: center;
  margin: 0;
  z-index: 10;
`;

const StyledSecondaryText = styled.text`
  font-family: FuturaBook;
  color: white;
  text-align: center;
  font-size: 1.75vh;
  z-index: 10;
`;

const StyledMobilePageDiv = styled.div`
  position: absolute;
  
  top: 4vh;
  
  height: 5vh;
  width: 10vw;
  z-index: 12;
  display: inline;
`;

const StyledMobileLandsPageDiv = styled.div`
  position: absolute;
  
  top: 7vh;
  
  height: 5vh;
  width: 10vw;
  z-index: 12;
  display: inline;
`;

const StyledMobilePageNo = styled.div`
  position: relative;
  
  top: 0vh;
  height: 2vh;
  
  width: 10vw;
  
  text-align: center;
  z-index: 12;
`;

const StyledMobilePageLocationText = styled.div`
  position: relative;
  height: 2vh;
  width: 10vw;

  text-align: center;
  z-index: 10;
`;

const StyledMobileLandsPageLocationText = styled.div`
  position: relative;
  height: 2vh;
  width: 10vw;
  top: 2vh;

  text-align: center;
  z-index: 10;
`;

const StyledMobileText = styled.text`
  font-family: FuturaHeavy;
  text-align: center;
  font-size: 2.5vh;
  color: white;
  justify-content: center;
  margin: 0;
  z-index: 10;
`;

const StyledMobileLandsText = styled.text`
  font-family: FuturaHeavy;
  text-align: center;
  font-size: 5vh;
  color: white;
  justify-content: center;
  margin: 0;
  z-index: 10;
`;

const StyledMobileSecondaryText = styled.text`
  font-family: FuturaBook;
  color: white;
  text-align: center;
  font-size: 1vh;
  z-index: 10;
`;

const StyledMobileLandscapeSecondaryText = styled.text`
  font-family: FuturaBook;
  color: white;
  text-align: center;
  font-size: 2.5vh;
  z-index: 10;
`;

export default StyledPageNumber;