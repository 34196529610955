import React, { Component} from 'react';
import styled from "styled-components";
import {isMobile} from 'react-device-detect';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title : "ohviz",
        }
    }

    render() {
        if (!isMobile) {
            return (
                <StyledHeader>
                    <header className="App-header">
                        <StyledWhiteHeaderText>{this.state.title}</StyledWhiteHeaderText>
                    </header>
                </StyledHeader>
            )
        } else if (this.props.orientation == 'Portrait') {
            return (
                <MobilePortraitStyledHeader>
                    <header className="App-header">
                       <MobilePortraitStyledWhiteHeaderText>{this.state.title}</MobilePortraitStyledWhiteHeaderText>
                    </header>
                </MobilePortraitStyledHeader>
            )
        } else {
            return (
                <MobileLandStyledHeader>
                    <header className="App-header">
                        <MobileLandStyledWhiteHeaderText>{this.state.title}</MobileLandStyledWhiteHeaderText>
                    </header>
                </MobileLandStyledHeader>
            )
        }
    }
}

const StyledHeader = styled.div`
  position: fixed;
  top: 2vh;
  left: 2vw;
  height: 80px;
  width: 15vw;
  z-index: 10;
  
  display: flex;
  align-items: center;
`;


const StyledWhiteHeaderText = styled.text`
  font-family: FuturaHeavy;
  color: white;
  text-align: right;
  font-size: 5vh;
  
  display: flex;
  align-items: center;
`;

const MobilePortraitStyledHeader = styled.div`
  position: fixed;
  top: 0.5vh;
  left: 1.5vw;
  height: 5vh;
  width: 15vw;
  z-index: 10;
  
  display: flex;
  align-items: center;
`;

const MobileLandStyledHeader = styled.div`
  position: fixed;
  top: 3.5vh;
  left: 1.5vw;
  height: 5vh;
  width: 15vw;
  z-index: 10;
  
  display: flex;
  align-items: center;
`;

const MobileLandStyledWhiteHeaderText = styled.text`
  font-family: FuturaHeavy;
  color: white;
  text-align: center;
  font-size: 9vh;
  
  display: flex;
  align-items: center;
`;

const MobilePortraitStyledWhiteHeaderText = styled.text`
  font-family: FuturaHeavy;
  color: white;
  text-align: center;
  font-size: 3.5vh;
  
  display: flex;
  align-items: center;
`;

export default Header;