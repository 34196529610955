import './App.css';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import Navigation from "./components/navigation";
import Header from "./components/header";
import Arrow from "./components/arrow";
import Home from "./pages/Home";
import About1 from "./pages/About1";
import Archviz from "./pages/Archviz";
import Products from "./pages/Products";
import Realtime from "./pages/Realtime";
import Animation from "./pages/Animation";
import Concepts from "./pages/Concepts";
import About2 from "./pages/About2";
import Contact from "./pages/Contact";
import { isMobile } from "react-device-detect";
import {useMediaQuery} from "react-responsive";

function App() {

    const { ref: home, inView: homeIsVisible } = useInView({
        threshold: 0.45,
    });;
    const { ref: about, inView: aboutIsVisible } = useInView({
        threshold: 0.45,
    });;
    const { ref: archviz, inView: archvizIsVisible } = useInView({
        threshold: 0.45,
    });;
    const { ref: product, inView: productIsVisible } = useInView({
        threshold: 0.45,
    });;
    const { ref: realtime, inView: realtimeIsVisible } = useInView({
        threshold: 0.45,
    });;
    const { ref: animation, inView: animationIsVisible } = useInView({
        threshold: 0.45,
    });;
    const { ref: concept, inView: conceptIsVisible } = useInView({
        threshold: 0.45,
    });;
    const { ref: about2, inView: about2IsVisible } = useInView({
        threshold: 0.45,
    });;
    const { ref: contact, inView: contactIsVisible } = useInView({
        threshold: 0.45,
    });;

    const orientation = getOrientation();

    function getOrientation() {
        var orientation = window.innerWidth > window.innerHeight ? "Landscape" : "Portrait";
        return orientation;
    }

    return (
        <div className="App">
            <Header orientation={orientation}/>
            <Arrow finalPageVisible={contactIsVisible}/>
            <Navigation homeIsVisible={homeIsVisible}
                        aboutIsVisible={aboutIsVisible}
                        contactIsVisible={contactIsVisible}
                        about2IsVisible={about2IsVisible}
                        conceptIsVisible={conceptIsVisible}
                        animationIsVisible={animationIsVisible}
                        realtimeIsVisible={realtimeIsVisible}
                        productIsVisible={productIsVisible}
                        archvizIsVisible={archvizIsVisible}
                        orientation={orientation}
            />
            <Home inputRef={home} id={"home"} homeIsVisible={homeIsVisible} orientation={orientation}/>
            <About1 inputRef={about} aboutIsVisible={aboutIsVisible} orientation={orientation}/>
            <Archviz inputRef={archviz} archvizIsVisible={archvizIsVisible} orientation={orientation}/>
            <Products inputRef={product} productIsVisible={productIsVisible} orientation={orientation}/>
            <Realtime inputRef={realtime} realtimeIsVisible={realtimeIsVisible} orientation={orientation}/>
            <Animation inputRef={animation} animationIsVisible={animationIsVisible} orientation={orientation}/>
            <Concepts inputRef={concept} conceptIsVisible={conceptIsVisible} orientation={orientation}/>
            <About2 inputRef={about2} samplesIsVisible={about2IsVisible} orientation={orientation}/>
            <Contact inputRef={contact} id={"contact"} contactIsVisible={contactIsVisible} orientation={orientation}/>
        </div>
    );
}

export default App;
