import React, { Component} from 'react';
import styled from "styled-components";
import {Image} from "semantic-ui-react";
import desktopBg from "../images/desktop/ohviz-page2.webp";
import mobileBg from "../images/mobile/ohviz-mobilepage2.webp";
import { isMobile } from "react-device-detect";

class About1 extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        console.log("location : " + window.location.hostname)

        if (isMobile && this.props.orientation === 'Portrait') {
            return (
                <Wrapper ref={this.props.inputRef}>
                    <StyledBackgroundImage>
                        <Image fluid src={ mobileBg }/>
                    </StyledBackgroundImage>
                </Wrapper>
            )
        } else {
            return (
                <Wrapper ref={this.props.inputRef}>
                    <StyledBackgroundImage>
                        <Image fluid src={ desktopBg }/>
                    </StyledBackgroundImage>
                </Wrapper>
            )
        }
    }
}

const Wrapper = styled.div`
  top: 0vh;
  left: 0vw;
`;

const StyledBackgroundImage = styled.div`
  position: relative;
  z-index: 1;
`;

export default About1;