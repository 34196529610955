import React, { useState } from 'react';
import styled from "styled-components";
import MasonryLayout from "../components/MasonryLayout/MasonryLayout";
import ContainerCard from "../components/ContainerCard/ContainerCard";
import images from "../images/images.json";
import Header from "../components/header";
import WorkMenu from "../components/workmenu";
import { isMobile } from "react-device-detect";
import MobileWorkMenu from "../components/mobileworkmenu";

function Work () {

    const [categoryImage, setCategoryImage] = useState(images.categories.all);

    const orientation = getOrientation();

    function getOrientation() {
        var orientation = window.innerWidth > window.innerHeight ? "Landscape" : "Portrait";
        return orientation;
    }

    if (isMobile) {
        return (
            <Wrapper id="work">
                <Header orientation={orientation}/>
                <MobileWorkMenu orientation={orientation}/>
                <StyledBackground>
                    <div className="flex justify-content-center" style={{marginTop: "40px", padding: '50px'}}>
                        <ContainerCard>
                            <div className={`flex justify-content-between align-items-center`}>
                                {/*<Dropdown title="All Images" items={ddItems} liftingDdTextUp={takeDdTitle} />*/}
                            </div>
                            <MasonryLayout images={categoryImage} local={isLocalServer()}/>
                        </ContainerCard>
                    </div>
                </StyledBackground>
            </Wrapper>
        )
    } else {
        return (
            <Wrapper id="work">
                <Header/>
                <WorkMenu/>
                <StyledBackground>
                    <div className="flex justify-content-center" style={{ marginTop: "70px", padding: '50px' }}>
                        <ContainerCard>
                            <div className={`flex justify-content-between align-items-center`}>
                                {/*<Dropdown title="All Images" items={ddItems} liftingDdTextUp={takeDdTitle} />*/}
                            </div>
                            <MasonryLayout images={categoryImage} local={isLocalServer()}/>
                        </ContainerCard>
                    </div>
                </StyledBackground>
            </Wrapper>
        )
    }
}

function isLocalServer() {
    if (window.location.hostname === 'localhost' ) {
        return true;
    } else if (window.location.hostname === "192.168.1.115") {
        return true;
    }  else {
        return false;
    }
}

const Wrapper = styled.div`
  top: 0vh;
  left: 0vw;
  background-color: #111111;
`;

const StyledBackground = styled.div`
  position: absolute;
  z-index: 1;
  min-height: 100vh;
  width: 100%;
  background-color: #111111;
`;

export default Work;
