import React, { Component} from 'react';
import styled from "styled-components";
import {IconContext} from "react-icons";
import {BsCaretDown} from "react-icons/bs";

class Arrow extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        if (!this.props.finalPageVisible) {
            return (
                <ArrowDiv>
                    <Arrow2div>
                        <IconContext.Provider value={{ color: '#6f6f6f', size: '3vh' }}>
                            <BsCaretDown/>
                        </IconContext.Provider>
                    </Arrow2div>
                </ArrowDiv>
            )
        }
    }
}

const ArrowDiv = styled.div`
  position: fixed;

  bottom: 5vh;
  left: 47.5vw;
  right: 47.5vw;
  height: 5vh;

  width: 5vw;

  cursor: pointer;
  z-index: 10;

  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;

  &:hover {
    animation-name: bounce;
    animation-timing-function: ease;
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

const Arrow2div = styled.div`
  position: absolute;

  bottom: 0vh;
  height: 5vh;

  left: 25%;
  right: 25%;
  width: 2.5vw;
  z-index: 10;
`;

export default Arrow;