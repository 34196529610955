import React, { Component} from 'react';
import styled from "styled-components";
import {IconContext} from "react-icons";
import {IoMenuOutline} from "react-icons/io5";

class WorkMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuClicked: false,
            optionsShowing: false
        }
        this.changeOptionsVisibility = this.changeOptionsVisibility.bind(this);
        this.getLocalServer = this.getLocalServer.bind(this);
    }

    changeOptionsVisibility() {
        this.setState({
            menuClicked: !this.state.menuClicked,
            optionsShowing: !this.state.optionsShowing
        });
    }

    getLocalServer() {
        if (window.location.hostname === "localhost" ||
            window.location.hostname === "127.0.0.1" ||
            window.location.hostname === "") {
            return "http://localhost:3000";
        } else if (window.location.hostname === "192.168.1.115") {
            return "192.168.1.115:3000";
        } else {
            return "https://ohviz.co.uk";
        }
    }

    render() {
        return (
            <MenuBarsDiv onClick={this.changeOptionsVisibility}>
                { this.state.menuClicked ? (
                    <StyledMenuBackground>
                        <MenuSection onClick={this.changeOptionsVisibility}>
                            <a href={this.getLocalServer()}>
                                <div>
                                    <StyledMenuText>home</StyledMenuText>
                                </div>
                            </a>
                        </MenuSection>
                        <MenuSection onClick={this.changeOptionsVisibility}>
                            <a href={this.getLocalServer() + "/#contact"}>
                                <div>
                                    <StyledMenuText>contact</StyledMenuText>
                                </div>
                            </a>
                        </MenuSection>
                    </StyledMenuBackground>
                ) : (
                    <HiddenMenuBackground/>
                )}
                <MenuBarsIconDiv>
                    <IconContext.Provider value={{ color: 'white', size: '4.5vh', style: { verticalAlign: 'middle' } }}>
                        <IoMenuOutline/>
                    </IconContext.Provider>
                </MenuBarsIconDiv>
            </MenuBarsDiv>
        )
    }
}

const MenuBarsDiv = styled.div`
  position: absolute;
  top: 5vh;
  right: 3vh;
  height: 5vh;
  width: 5vw;
  text-align: center;
  cursor: pointer;
  z-index: 11;
`;

const HiddenMenuBackground = styled.div`
  position: absolute;
  
  top: 1vh;
  height: 18vh;

  left: -10vw;
  width: 10vw;
  z-index: 10;
  display: none;
`;

const MenuBarsIconDiv = styled.div`
  position: absolute;
  width: 100%;
  z-index: 11;
`;

const MenuSection = styled.div`
    position: relative;
    height: 33%;
    text-align: center;
  
  &:hover {
    background-color: gray;
  }
`;

const StyledMenuBackground = styled.div`
  position: absolute;
  
  top: 1vh;
  height: 10vh;

  left: -10vw;
  width: 10vw;
  z-index: 10;
  display: inline;
`;

const StyledMenuText = styled.text`
  font-family: FuturaBook;
  color: white;
  text-align: left;
  font-size: 2.5vh;
`;

export default WorkMenu;