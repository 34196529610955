import React, { Component} from 'react';
import styled from "styled-components";
import Menu from "./menu";
import StyledPageNumber from "./styledPageNumber";
import {isMobile} from 'react-device-detect';
import MobileMenu from "./mobilemenu";

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        if (!isMobile) {
            return (
                <StyledNavigation>
                    <Menu/>
                    <StyledTopLine>
                        <CalculatePageNumber homeIsVisible={this.props.homeIsVisible}
                                             aboutIsVisible={this.props.aboutIsVisible}
                                             contactIsVisible={this.props.contactIsVisible}
                                             archvizIsVisible={this.props.archvizIsVisible}
                                             productIsVisible={this.props.productIsVisible}
                                             realtimeIsVisible={this.props.realtimeIsVisible}
                                             animationIsVisible={this.props.animationIsVisible}
                                             conceptIsVisible={this.props.conceptIsVisible}
                                             about2IsVisible={this.props.about2IsVisible}
                                             orientation={this.props.orientation}
                        />
                    </StyledTopLine>
                    <StyledBottomLine/>
                </StyledNavigation>

            )
        } else if (isMobile && this.props.orientation === 'Portrait') {
            return (
                <StyledMobileNavigation>
                    <MobileMenu orientation={this.props.orientation}/>
                    <CalculatePageNumber homeIsVisible={this.props.homeIsVisible}
                                         aboutIsVisible={this.props.aboutIsVisible}
                                         contactIsVisible={this.props.contactIsVisible}
                                         archvizIsVisible={this.props.archvizIsVisible}
                                         productIsVisible={this.props.productIsVisible}
                                         realtimeIsVisible={this.props.realtimeIsVisible}
                                         animationIsVisible={this.props.animationIsVisible}
                                         conceptIsVisible={this.props.conceptIsVisible}
                                         about2IsVisible={this.props.about2IsVisible}
                                         orientation={this.props.orientation}
                    />
                </StyledMobileNavigation>
            )
        }
        else {
            return (
                <StyledLandMobileNavigation>
                    <MobileMenu orientation={this.props.orientation}/>
                    <CalculatePageNumber homeIsVisible={this.props.homeIsVisible}
                                         aboutIsVisible={this.props.aboutIsVisible}
                                         contactIsVisible={this.props.contactIsVisible}
                                         archvizIsVisible={this.props.archvizIsVisible}
                                         productIsVisible={this.props.productIsVisible}
                                         realtimeIsVisible={this.props.realtimeIsVisible}
                                         animationIsVisible={this.props.animationIsVisible}
                                         conceptIsVisible={this.props.conceptIsVisible}
                                         about2IsVisible={this.props.about2IsVisible}
                                         orientation={this.props.orientation}
                    />
                </StyledLandMobileNavigation>
            )
       }
    }
}

const StyledNavigation = styled.div`
  position: fixed;
  
  top: 5vh;
  bottom: 5vh;
  height: 90vh;

  right: 1vw;
  width: 5vw;
  z-index: 10;
  border-radius: 10px;
`;

const StyledTopLine = styled.div`
  position: absolute;
  top: 7.5vh;
  left: 49%;
  height: 32.5vh;
  width: 1.5%;
  background-color: #6f6f6f;
`;

const StyledBottomLine = styled.div`
  position: absolute;
  bottom: 7.5vh;
  left: 49%;
  height: 32.5vh;
  
  width: 1.5%;
  background-color: #6f6f6f;
`;

const CalculatePageNumber = ({homeIsVisible,
                                 aboutIsVisible,
                                 archvizIsVisible,
                                 productIsVisible,
                                 realtimeIsVisible,
                                 animationIsVisible,
                                 conceptIsVisible,
                                 about2IsVisible,
                                 contactIsVisible,
                                 orientation}) => {
    if (homeIsVisible) {
        return <StyledPageNumber pageNo={"01"} pageTitle={"home"} orientation={orientation}/>;
    } else if (aboutIsVisible) {
        return <StyledPageNumber pageNo={"02"} pageTitle={"about"} orientation={orientation}/>;
    } else if (archvizIsVisible) {
        return <StyledPageNumber pageNo={"03"} pageTitle={"archviz"} orientation={orientation}/>;
    } else if (productIsVisible) {
        return <StyledPageNumber pageNo={"04"} pageTitle={"product"} orientation={orientation}/>;
    } else if (realtimeIsVisible) {
        return <StyledPageNumber pageNo={"05"} pageTitle={"real-time"} orientation={orientation}/>;
    } else if (animationIsVisible) {
        return <StyledPageNumber pageNo={"06"} pageTitle={"animation"} orientation={orientation}/>;
    } else if (conceptIsVisible) {
        return <StyledPageNumber pageNo={"07"} pageTitle={"concepts"} orientation={orientation}/>;
    } else if (about2IsVisible) {
        return <StyledPageNumber pageNo={"08"} pageTitle={"about"} orientation={orientation}/>;
    } else if (contactIsVisible) {
        return <StyledPageNumber pageNo={"09"} pageTitle={"contact"} orientation={orientation}/>;
    }
}

const StyledMobileNavigation = styled.div`
  position: fixed;
  
  top: 0.5vh;
  height: 10vh;
  width: 10vw;

  right: 1vw;
  
  z-index: 10;
`;

const StyledLandMobileNavigation = styled.div`
  position: fixed;
  
  top: 0.5vh;
  height: 10vh;
  width: 7.5vw;

  right: 1vw;
  
  z-index: 10;
`;

export default Navigation;