import React, { Component} from 'react';
import styled from "styled-components";
import {IconContext} from "react-icons";
import {IoMenuOutline} from "react-icons/io5";
import {Link} from "react-scroll";
import { isMobile } from "react-device-detect";

class MobileMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuClicked: false,
            optionsShowing: false,
            // device: !!navigator.maxTouchPoints ? 'mobile' : 'computer',
            // orientation: !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape'
        }
        this.changeOptionsVisibility = this.changeOptionsVisibility.bind(this);
        this.getLocalServer = this.getLocalServer.bind(this);
    }

    changeOptionsVisibility() {
        this.setState({
            menuClicked: !this.state.menuClicked,
            optionsShowing: !this.state.optionsShowing
        });
    }

    getLocalServer() {
        if (window.location.hostname === "localhost" ||
            window.location.hostname === "127.0.0.1" ||
            window.location.hostname === "") {
            return "http://localhost:3000/work";
        } else if (window.location.hostname === "192.168.1.115") {
            return "/work";
        } else {
            console.log("location : " + window.location.hostname)
            return "https://ohviz.co.uk/work";
        }
    }

    render() {
        if (this.props.orientation === 'Portrait') {
            return (
                <MenuBarsDiv onClick={this.changeOptionsVisibility}>
                    {this.state.menuClicked ? (
                        <StyledMenuBackground>
                            <Link activeClass="active" to="home" spy={true} smooth={true}>
                                <MenuSection onClick={this.changeOptionsVisibility}>
                                    <StyledMenuText>home</StyledMenuText>
                                </MenuSection>
                            </Link>
                            <MenuSection onClick={this.changeOptionsVisibility}>
                                <a href={this.getLocalServer()}>
                                    <div>
                                        <StyledMenuText>work</StyledMenuText>
                                    </div>
                                </a>
                            </MenuSection>
                            <Link to="contact" spy={true} smooth={true}>
                                <MenuSection onClick={this.changeOptionsVisibility}>
                                    <StyledMenuText>contact</StyledMenuText>
                                </MenuSection>
                            </Link>
                        </StyledMenuBackground>
                    ) : (
                        <HiddenMenuBackground/>
                    )}
                    <MenuBarsIconDiv>
                        <IconContext.Provider value={{color: 'white', size: '4.5vh', style: {verticalAlign: 'middle'}}}>
                            <IoMenuOutline/>
                        </IconContext.Provider>
                    </MenuBarsIconDiv>
                </MenuBarsDiv>
            )
        } else {
            return (
                <MenuLandsBarsDiv onClick={this.changeOptionsVisibility}>
                    {this.state.menuClicked ? (
                        <StyledMenuBackground>
                            <Link activeClass="active" to="home" spy={true} smooth={true}>
                                <MenuSection onClick={this.changeOptionsVisibility}>
                                    <StyledMenuLandsText>home</StyledMenuLandsText>
                                </MenuSection>
                            </Link>
                            <MenuSection onClick={this.changeOptionsVisibility}>
                                <a href={this.getLocalServer()}>
                                    <div>
                                        <StyledMenuLandsText>work</StyledMenuLandsText>
                                    </div>
                                </a>
                            </MenuSection>
                            <Link to="contact" spy={true} smooth={true}>
                                <MenuSection onClick={this.changeOptionsVisibility}>
                                    <StyledMenuLandsText>contact</StyledMenuLandsText>
                                </MenuSection>
                            </Link>
                        </StyledMenuBackground>
                    ) : (
                        <HiddenMenuBackground/>
                    )}
                    <MenuBarsIconDiv>
                        <IconContext.Provider value={{color: 'white', size: '8.5vh', style: {verticalAlign: 'middle'}}}>
                            <IoMenuOutline/>
                        </IconContext.Provider>
                    </MenuBarsIconDiv>
                </MenuLandsBarsDiv>
            )
        }
    }
}

const MenuBarsDiv = styled.div`
  position: relative;
  top: 0vh;
  height: 5vh;
  width: 10vw;
  text-align: center;
  cursor: pointer;
  z-index: 11;
`;

const MenuLandsBarsDiv = styled.div`
  position: relative;
  top: 0vh;
  height: 5vh;
  
  width: 10vw;
  text-align: center;
  cursor: pointer;
  z-index: 11;
`;

const HiddenMenuBackground = styled.div`
  position: absolute;
  
  top: 1vh;
  height: 18vh;

  left: -10vw;
  width: 10vw;
  z-index: 10;
  display: none;
`;

const MenuBarsIconDiv = styled.div`
  position: absolute;
  width: 100%;
  z-index: 11;
`;

const MenuSection = styled.div`
    position: relative;
    height: 33%;
    text-align: center;
  
  &:hover {
    background-color: gray;
  }
`;

const StyledMenuBackground = styled.div`
  position: absolute;
  
  top: 1vh;
  height: 15vh;

  left: -11vw;
  width: 10vw;
  z-index: 10;
  display: inline;
`;

const StyledMenuText = styled.text`
  font-family: FuturaBook;
  color: white;
  text-align: left;
  font-size: 1.5vh;
`;

const StyledMenuLandsText = styled.text`
  font-family: FuturaBook;
  color: white;
  text-align: left;
  font-size: 3.5vh;
`;

export default MobileMenu;