import React, { Component} from 'react';
import {Image} from "semantic-ui-react";
import styled from 'styled-components';
import desktopBg from "../images/desktop/ohviz-page6.webp";
import mobileBg from "../images/mobile/ohviz-mobilepage6.webp";
import { isMobile } from "react-device-detect";

class Animation extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        if (isMobile && this.props.orientation === 'Portrait') {
            return (
                <Wrapper ref={this.props.inputRef}>
                    <StyledBackgroundImage>
                        <Image fluid src={ mobileBg }/>
                    </StyledBackgroundImage>
                </Wrapper>
            )
        } else {
            return (
                <Wrapper ref={this.props.inputRef}>
                    <StyledBackgroundImage>
                        <Image fluid src={ desktopBg }/>
                    </StyledBackgroundImage>
                </Wrapper>
            )
        }
    }
}

const Wrapper = styled.div`
  top: 0vh;
  left: 0vw;
`;

const StyledBackgroundImage = styled.div`
  position: static;
`;

export default Animation;
